import React from 'react'

import Donation_details from "../Components/Donation_details";
import Navbar from "../Components/Navbar";
import Thankyou from "./Thankyou";
import '../App.css'
import Donation_Footer from "../Components/Donation_Footer";

import { useLocation, useSearchParams } from 'react-router-dom';

const DonationPage = () => {

    const location = useLocation();
    const [searchParams] = useSearchParams();
  
    // Get the value of 'param1'
    const paramsObject = Object.fromEntries([...searchParams]);


  return (
    <div className="parent_div">
<Navbar/>
<Donation_details params={paramsObject}/>
<Donation_Footer/>
</div>
  )
}

export default DonationPage