import Donation_details from "./Components/Donation_details";
import Navbar from "./Components/Navbar";
import Thankyou from "./Pages/Thankyou";
import './App.css'
import Donation_Footer from "./Components/Donation_Footer";
import { Route, Routes } from "react-router-dom";
import Router from "./Components/Router";

function App() {
  return (
    <Router/>

  );
}

export default App;
