import React from 'react'
import '../style/navbar.css'



const Navbar = () => {
  return (
    <div className='navbar_div'>
        <div className='logo'>
            <img src='logo.png'></img>
            <div>Ekal Gram Sangathan</div>
        </div>

        <button onClick={()=>window.location="https://www.ekalgs.org"} className='btn_home'>GO BACK TO HOME</button>

    </div>
  )
}

export default Navbar