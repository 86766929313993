import React from 'react'

import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

// import DashboardLayout from 'src/layouts/dashboard';

// export const IndexPage = lazy(() => import('src/pages/app'));
// export const BlogPage = lazy(() => import('src/pages/blog'));
// export const UserPage = lazy(() => import('src/pages/user'));
// export const LoginPage = lazy(() => import('src/pages/login'));
// export const ProductsPage = lazy(() => import('src/pages/products'));
// export const Page404 = lazy(() => import('src/pages/page-not-found'));

import Donation_details from "./Donation_details";
import Navbar from "./Navbar";
import Thankyou from "../Pages/Thankyou";
import '../App.css'
import Donation_Footer from "./Donation_Footer";
import DonationPage from '../Pages/DonationPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
path: '/',
element: <DonationPage/>
    },
    {
      path: 'login',
      element: <Donation_details />,
    },
    {
      path: '404',
      element: <Thankyou />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
