import React from 'react'
import '../style/donation_footer.css'

const Donation_Footer = () => {
  return (
    <div className='footer_outer_div'>
        Copyright © 2023 Ekal Gram Sangathan | Powered by AIL –
        <a className='footer-link' href='https://ail.auriga.co.in/'>Auriga Innovative Labs</a> &nbsp;|&nbsp;
        <a className='footer-link' href='https://ekalgs.org/privacy-policy-2/'>Privacy Policy</a> &nbsp;|&nbsp;
        <a className='footer-link' href='https://ekalgs.org/terms-of-use/'>Terms & Conditions</a> &nbsp;|&nbsp;
        <a className='footer-link' href='https://ekalgs.org/refund-policies/'>Refund & Cancellation</a>
    </div>
  )
}

export default Donation_Footer