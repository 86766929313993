import React, { useState, useEffect } from "react";

// import { Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import jsPDF from 'jspdf';

// import logo from '../images/logo.png';

import 'react-toastify/dist/ReactToastify.css';
import Thankyou from "../Pages/Thankyou";
import Loading from "./Loading";

import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import '../style/donation_details.css'

import { startTransac, razorpay_order_create } from '../apis/Api.js'

import axios from "axios";
import Donation_Footer from "./Donation_Footer";



const Donation_details = ({ params }) => {

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    // const navigate = useNavigate();

    const initialState = {
        donor_name: (params['donor_name'] && params['donor_name'] != 'undefined') ? params['donor_name'] : '',
        donor_phone: (params['donor_phone'] && params['donor_phone'] != 'undefined') ? params['donor_phone'] : '',
        donor_dob: (params['donor_dob'] && params['donor_dob'] != 'undefined') ? params['donor_dob'] : '',
        donor_anniversary: (params['donor_anniversary'] && params['donor_anniversary'] != 'undefined') ? params['donor_anniversary'] : '',
        donor_pan: (params['donor_pan'] && params['donor_pan'] != 'undefined') ? params['donor_pan'] : '',
        donor_anchal: (params['donor_anchal'] && params['donor_anchal'] != 'undefined') ? params['donor_anchal'] : '',
        donor_bhaag: (params['donor_bhaag'] && params['donor_bhaag'] != 'undefined') ? params['donor_bhaag'] : '',
        donor_sambhaag: (params['donor_sambhaag'] && params['donor_sambhaag'] != 'undefined') ? params['donor_sambhaag'] : '',
        donor_sanch: (params['donor_sanch'] && params['donor_sanch'] != 'undefined') ? params['donor_sanch'] : '',
        donor_city: (params['donor_city'] && params['donor_city'] != 'undefined') ? params['donor_city'] : '',
        donor_pincode: (params['donor_pincode'] && params['donor_pincode'] != 'undefined') ? params['donor_pincode'] : '',
        introducer_type: (params['introducer_type'] && params['introducer_type'] != 'undefined') ? params['introducer_type'] : '',
        introducer_anchal: (params['introducer_anchal'] && params['introducer_anchal'] != 'undefined') ? params['introducer_anchal'] : '',
        introducer_bhaag: (params['introducer_bhaag'] && params['introducer_bhaag'] != 'undefined') ? params['introducer_bhaag'] : '',
        introducer_sambhaag: (params['introducer_sambhaag'] && params['introducer_sambhaag'] != 'undefined') ? params['introducer_sambhaag'] : '',
        introducer_name: (params['introducer_name'] && params['introducer_name'] != 'undefined') ? params['introducer_name'] : '',
        introducer_phone: (params['introducer_phone'] && params['introducer_phone'] != 'undefined') ? params['introducer_phone'] : '',
        introducer_contribution_type: (params['introducer_contribution_type'] && params['introducer_contribution_type'] != 'undefined') ? params['introducer_contribution_type'] : '',
        introducer_sanch: (params['introducer_sanch'] && params['introducer_sanch'] != 'undefined') ? params['introducer_sanch'] : '',
        amount: (params['amount'] && params['amount'] != 'undefined') ? params['amount'] : ''
    };


    const [isLoading, setIsLoading] = useState(false);
    const [isThankyou, setisThankyou] = useState(false);
    const [base64_receipt_str, setBase64_receipt_str] = useState(false);
    const [sanch_list_donor, setSanch_list_donor] = useState([]);
    const [sanch_list_introducer, setSanch_list_introducer] = useState([]);
    const [DonationAmount, setDonationAmount] = useState("false");
    const [formData, setformData] = useState(initialState);
    const { donor_name, donor_anniversary, donor_anchal, donor_bhaag, donor_city, donor_dob, donor_pincode, donor_sambhaag, donor_pan, donor_phone, donor_sanch, introducer_type, introducer_anchal, introducer_bhaag, introducer_contribution_type, introducer_name, introducer_phone, introducer_sambhaag, introducer_sanch, amount } = formData;

    // const sanch_list = ['Beltara', 'Podibatra', 'Pali', 'Chaitama', 'Jatga', 'Podiuproda', 'Morga', 'Dipka', 'Devpahari', 'Balko'];
    const sambhaag_list = ["Chhattisgarh"];
    const anchal_list = ['Raigarh', 'Kunkuri', 'Ambikapur', 'Surajpur', 'Manendragarh', 'Korba'];
    const anchal_sanch_map = {
        'Raigarh': ['Dharmjaigarh', 'Kapu', 'Khamar', 'Chhal', 'Gharghoda', 'Nawapara', 'Lailunga', 'Tamnar', 'Dabhara', 'Jobi'],
        'Kunkuri': ['Manora', 'Bagicha', 'Sanna', 'Sahidand', 'Duldula', 'Kunkuri', 'Kardega', 'Tapkara', 'Ludeg', 'Bagbahar', 'Kansabel'],
        'Ambikapur': ['Udaipur', 'Lakhanpur', 'Darima', 'Lundra', 'Batoli', 'Sitapur', 'Mainpath', 'Rajpur', 'Shankargarh', 'Kusmi'],
        'Surajpur': ['Premnagar', 'Shrinagar', 'Krishnapur ', 'Ganeshpur ', 'Ketaka', 'Jaynagar', 'Odagi ', 'Biharpur ', 'Karonji', 'Bhaiyathan', 'Adhina Salka', 'Pratappur'],
        'Manendragarh': ['Janakpur', 'Kelhari', 'Nagpur', 'Katghodi', 'Ramgarh', 'Manendragarh', 'Khadgawan', 'Baikunthpur', 'Patna', 'PodiBachra'],
        'Korba': ['Beltara', 'Podibatra', 'Pali', 'Chaitama', 'Jatga', 'Podiuproda', 'Morga', 'Dipka', 'Devpahari', 'Balko']
    };
    const bhaag_list = ["Uttar Chhattisgarh"];

    const handleGenerateLinkClick = () => {
        const params = new URLSearchParams(formData);
        const generated_link = window.location.href.toString()+'?'+params.toString()

        navigator.clipboard.writeText(generated_link)
        .then(() => {
            toast.success("Link copied to clipboard")
        //   console.log('Text copied to clipboard');
        })
        .catch((err) => {
            toast.success("Failed to copy the generated link.")
        //   console.error('Failed to copy text: ', err);
        });

      };


    const handleAmountChange = (e) => {
        const { name, value } = e.target;
        if (e.target.checked === true) {

            setformData({ ...formData, [name]: "22000" });
        }
    };
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        if (name === "amount") {
            document.getElementById("inline-radio-1").checked = false;
        }
        if (name === "donor_anchal") {
            setSanch_list_donor(anchal_sanch_map[value]);
        }
        if (name === "introducer_anchal") {
            setSanch_list_introducer(anchal_sanch_map[value]);
        }
        setformData({ ...formData, [name]: value });

    };

    const print_amount = () => {
        console.log(formData.amount);
        console.log(document.getElementById("inline-radio-1").checked);
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const downloadImage = (b64_jpg) => {

        // console.log(b64_jpg);

        // Replace this with your Base64 encoded image data
        const base64Data = 'data:image/jpeg;base64,' + b64_jpg;

        // Create a new jsPDF instance
        const pdf = new jsPDF('landscape');

        // Add an image to the PDF
        // pdf.addImage(base64Data, 'JPEG', 1, 1, 3509, 2480); // You can adjust the position and size as needed
        pdf.addImage(base64Data, 'JPEG', 1, 1, 292, 205); // You can adjust the position and size as needed

        // Save the PDF as a file
        pdf.save('Ekal GS Donation Receipt.pdf');

        // console.log(base64Data);

        // // Decode Base64 data
        // const binaryData = atob(base64Data.split(',')[1]);

        // // Create a Blob from the binary data
        // const blob = new Blob([new Uint8Array(Array.from(binaryData).map(char => char.charCodeAt(0)))], { type: 'image/jpeg' });

        // // Create a URL for the Blob
        // const url = URL.createObjectURL(blob);

        // // Create an anchor element for downloading
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'image.jpg';

        // // Trigger the download
        // a.click();

        // // Release the object URL
        // URL.revokeObjectURL(url);
    };


    const handleOpenRazorpay = async (data) => {

        const options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
            "amount": data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Ekal Gram Sangathan", //your business name
            "description": "Payment for Donation",
            "image": "https://ekalgs.org/wp-content/uploads/2023/05/Ekal-Logo-148x164.png",
            "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (resp) {
                // console.log("done");

                try {
                    setIsLoading(true);
                    const response = await axios.post(
                        `${BACKEND_URL}/api/razorpay/verify`,
                        { response: resp, data: formData }
                    );

                    console.log(response);
                    setIsLoading(false);
                    if (response.status === 200) {
                        console.log(response.data.message);
                        toast.success(response.data.message);

                        // console.log(response.data.base64_jpg);

                        setBase64_receipt_str(response.data.base64_jpg);
                        setisThankyou(true);
                        // downloadImage(response.data.base64_jpg);


                    }
                    else {
                        toast.error("Some Unexpected Error has occured while processing your payment. Please Retry.")
                    }
                    // return response.data;
                } catch (error) {
                    setIsLoading(false);
                    const message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    toast.error(message);
                    console.log(message);

                }
                setIsLoading(false);


            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                "name": formData.donor_name, //your customer's name
                // "email": "gaurav.kumar@example.com", 
                "contact": formData.donor_phone  //Provide the customer's phone number for better conversion rates 
            },
            "notes": { "notes": formData },
            "theme": {
                "color": "#f1592a"
            }
        };

        console.log(formData);
        var rzp = new window.Razorpay(options);
        rzp.open();



    }

    const donate = async (e) => {
        e.preventDefault();


        // for(let i = 0;i<Object.keys(formData).length;i++){

        if (formData.introducer_contribution_type === "") {
            alert("Please fill Type of Contribution");
            return;
        }



        setIsLoading(true);

        try {
            const response = await axios.post(
                `${BACKEND_URL}/api/razorpay/order`,
                formData
            );

            console.log(response);
            setIsLoading(false);
            if (response.status === 200) {
                console.log(response.data.message);
                handleOpenRazorpay(response.data.data);
                //   toast.success("User Registered successfully");

            }
            else {
                toast.error("Some Unexpected Error has occured while processing your payment.")
            }
            // return response.data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            setIsLoading(false);
            toast.error(message);
            console.log(message);

        }
    };


    useEffect(() => {
        console.log('aaya')
        console.log(params['amount'])

        // const { name, value } = e.target;
        if (params['amount'] && params['amount'] != 'undefined') {
            console.log('aaya')
            if(params['amount'] == 22000)
                document.getElementById("inline-radio-1").checked = true;
        }
        if (params['donor_anchal'] && params['donor_anchal'] != 'undefined'){
            console.log('aaya')
            setSanch_list_donor(anchal_sanch_map[params['donor_anchal']]);
            if(params['donor_sanch']){
                setformData({ ...formData, ['donor_sanch']: formData['donor_sanch'] });
            }
        }
        if (params['introducer_anchal'] && params['introducer_anchal'] != 'undefined'){
            console.log('aaya')
            setSanch_list_introducer(anchal_sanch_map[params['introducer_anchal']]);
            if(params['introducer_sanch']){
                setformData({ ...formData, ['introducer_sanch']: formData['introducer_sanch'] });
            }
        }
        // setformData({ ...formData, [name]: value });

      }, []);



    return (
        <div className='form_donation_outer_div'>

            <ToastContainer />

            {isLoading && <Loading/>}


            {!isLoading && !isThankyou && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>


                <div className="donation_form_head">DONATE TO EKAL GRAM SANGATHAN</div>

                <div className="donation_form_subhead">The EkalVidyalaya operates on ₹22,000 a year. EkalGS is able to maintain such a low cost because it works with a low overhead budget and is supported by the villagers with whom it works. So, Support us in our cause and become a part of our initiative.</div>

                <div class="donation_form_note">Note: Donations to EkalGram Sangathan(EkalGS) are tax exempt u/s 80G of the Income Tax act, 1961, as allowed by law.</div>

                <div class="donation_form_note2">EkalGS will not use donor information for any commercial purpose or distribute to any commercial entity. It will be strictly used to communicate with the donor about Ekal activities in India where Ekal schools are operated.</div>


                <Form onSubmit={donate}>

                    <div className="amount_outer_div">
                        <div key={`inline-radio`} className="defined_amount_div">
                            <Form.Check
                                inline
                                label="₹ 22000"
                                name="amount"
                                type="radio"
                                id="inline-radio-1"
                                onChange={handleAmountChange}
                            />
                            <div><b>For 1 School One Year</b></div>
                        </div>

                        <div style={{ "fontSize": "1.5rem", "color": "#585858", "marginLeft": "2vw", "marginRight": "2vw" }}>or</div>

                        <Form.Group as={Col} controlId="amount" className="donation_amount_custom_box">
                            <Form.Label>Donation Amount</Form.Label>
                            <Form.Control name="amount"
                                value={amount}
                                onChange={handleInputChange} required />
                        </Form.Group>

                    </div>

                    <div className='donor_details_head'>Donor Details</div>


                    {/* {Object.entries(params).map(([key, value]) => (
                        <p key={key}>
                        {key}: {value}
                        </p>
                    ))} */}


                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="donor_name">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Your Full Name" name="donor_name"
                                value={donor_name}
                                onChange={handleInputChange} required />
                        </Form.Group>

                        <Form.Group as={Col} controlId="donor_phone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="number" placeholder="Enter Your Phone Number" name="donor_phone"
                                value={donor_phone}
                                onChange={handleInputChange} required />
                        </Form.Group>
                    </Row>

                    <Row>

                        <Form.Group as={Col} className="mb-3" controlId="donor_pan">
                            <Form.Label>PAN Number</Form.Label>
                            <Form.Control placeholder="Enter You PAN No."
                                name="donor_pan"
                                value={donor_pan}
                                onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" controlId="donor_dob">
                            <Form.Label>Donor's D.O.B</Form.Label>
                            <Form.Control type='date' placeholder="Enter Your Date Of Birth" name="donor_dob"
                                value={donor_dob}
                                onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" controlId="donor_anniversary">
                            <Form.Label>Donor's Anniversary</Form.Label>
                            <Form.Control type='date' placeholder="Enter Donor's Anniversary"
                                name="donor_anniversary"
                                value={donor_anniversary}
                                onChange={handleInputChange} />
                        </Form.Group>

                    </Row>

                    <Row className="mb-3">



                        <Form.Group as={Col} controlId="donor_city">
                            <Form.Label>Donor's Village/ City Name</Form.Label>
                            <Form.Control name="donor_city"
                                value={donor_city}
                                onChange={handleInputChange} required />
                        </Form.Group>


                        <Form.Group as={Col} controlId="donor_pincode">
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control type='number'
                                name="donor_pincode"
                                value={donor_pincode}
                                onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="donor_sambhaag">
                            <Form.Label>Donor's Sambhaag</Form.Label>
                            <Form.Select aria-label="Default select example" name="donor_sambhaag"
                                value={donor_sambhaag}
                                onChange={handleInputChange}>
                                <option>Select Sambhaag</option>
                                {
                                    sambhaag_list.map((sambhaag) =>
                                        <option value={sambhaag}>{sambhaag.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>


                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="donor_bhaag">
                            <Form.Label>Donor's Bhaag</Form.Label>
                            <Form.Select aria-label="Default select example" name="donor_bhaag"
                                value={donor_bhaag}
                                onChange={handleInputChange}>
                                <option>Select Bhaag</option>
                                {
                                    bhaag_list.map((bhaag) =>
                                        <option value={bhaag}>{bhaag.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="donor_anchal">
                            <Form.Label>Donor's Anchal</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="donor_anchal"
                                value={donor_anchal}
                                onChange={handleInputChange}>
                                <option>Select Anchal</option>
                                {
                                    anchal_list.map((anchal) =>
                                        <option value={anchal}>{anchal.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="donor_sanch">
                            <Form.Label>Donor's Sanch</Form.Label>
                            <Form.Select aria-label="Default select example" name="donor_sanch"
                                value={donor_sanch}
                                onChange={handleInputChange}>
                                <option>Select Sanch</option>
                                {
                                    sanch_list_donor.map((sanch) =>
                                        <option value={sanch}>{sanch.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <hr></hr>



                    <div className='introducer_details_head'>Introducer Details</div>



                    <Row className="mb-3">

                        <Form.Group as={Col} controlId="introducer_name">
                            <Form.Label>Introducer/Promoter's Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Introducer/Promoter's Name" name="introducer_name"
                                value={introducer_name}
                                onChange={handleInputChange} required />
                        </Form.Group>

                        <Form.Group as={Col} controlId="introducer_phone">
                            <Form.Label>Introducer/Promoter's Phone</Form.Label>
                            <Form.Control type="number" placeholder="Enter Introducer/Promoter's Phone " name="introducer_phone"
                                value={introducer_phone}
                                onChange={handleInputChange} required />
                        </Form.Group>

                    </Row>

                    <Row className="mb-3">

                        {/* <Form.Group as={Col} controlId="introducer_type">
                            <Form.Label>Introducer/Promoter Type</Form.Label>
                            <Form.Select aria-label="Default select example" name="introducer_type"
                                value={introducer_type}
                                onChange={handleInputChange}>
                                <option>Select Introducer Type</option>
                                <option value="कार्यकर्ता">कार्यकर्ता</option>
                                <option value="समिति">समिति</option>
                            </Form.Select>
                        </Form.Group> */}

<Form.Group as={Col} controlId="introducer_type">
    <Form.Label>Introducer/Promoter Type</Form.Label>
    <div>
        <Form.Check 
            type="radio" 
            id="कार्यकर्ता" 
            label="कार्यकर्ता" 
            name="introducer_type" 
            value="कार्यकर्ता" 
            checked={introducer_type === "कार्यकर्ता"} 
            onChange={handleInputChange} 
        />
        <Form.Check 
            type="radio" 
            id="समिति" 
            label="समिति" 
            name="introducer_type" 
            value="समिति" 
            checked={introducer_type === "समिति"} 
            onChange={handleInputChange} 
        />
    </div>
</Form.Group>

                        <Form.Group as={Col} controlId="introducer_contribution_type">
                            <Form.Label>Type of Contribution</Form.Label>
                            <Form.Select aria-label="Default select example" name="introducer_contribution_type"
                                value={introducer_contribution_type}
                                onChange={handleInputChange} defaultValue="" required>
                                <option value="" >Select Contribution</option>
                                <option value="Sadasyata Shulk">सदस्यता शुलक</option>
                                <option value="Prashikshan Sahayog">प्रशिक्षण सहयोग</option>
                                <option value="Vidyalaya Sahayog">विद्यालय सहयोग</option>
                                <option value="Hari Katha Yojana">हरि कथा योजना</option>
                                <option value="Gramotthaan Yojana">ग्रामोत्थान योजना</option>
                                <option value="Gram Swaraj Yojana">ग्राम स्वराज योजना</option>
                                <option value="Aarogya Yojana">आरोग्य योजना</option>
                                <option value="Mahila Vibhaag">महिला विभाग</option>
                                <option value="Yuva Vibhaag">युवा विभाग</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="introducer_sambhaag">
                            <Form.Label>Introducer/Promoter Sambhaag</Form.Label>
                            <Form.Select aria-label="Default select example" name="introducer_sambhaag"
                                value={introducer_sambhaag}
                                onChange={handleInputChange}>
                                <option>Select Sambhaag</option>
                                {
                                    sambhaag_list.map((sambhaag) =>
                                        <option value={sambhaag}>{sambhaag.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>


                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="introducer_bhaag">
                            <Form.Label>Introducer/Promoter Bhaag</Form.Label>
                            <Form.Select aria-label="Default select example" name="introducer_bhaag"
                                value={introducer_bhaag}
                                onChange={handleInputChange}>
                                <option>Select Bhaag</option>
                                {
                                    bhaag_list.map((bhaag) =>
                                        <option value={bhaag}>{bhaag.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="introducer_anchal">
                            <Form.Label>Introducer/Promoter Anchal</Form.Label>
                            <Form.Select aria-label="Default select example"
                                name="introducer_anchal"
                                value={introducer_anchal}
                                onChange={handleInputChange}>
                                <option>Select Anchal</option>
                                {
                                    anchal_list.map((anchal) =>
                                        <option value={anchal}>{anchal.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="introducer_sanch">
                            <Form.Label>Introducer/Promoter Sanch</Form.Label>
                            <Form.Select aria-label="Default select example" name="introducer_sanch"
                                value={introducer_sanch}
                                onChange={handleInputChange}>
                                <option>Select Sanch</option>
                                {
                                    sanch_list_introducer.map((sanch) =>
                                        <option value={sanch}>{sanch.toString()}</option>
                                    )
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>






                    <Button className='paynow_btn' variant="primary" type="submit">
                        Pay Now
                    </Button>

                    &nbsp;&nbsp; OR &nbsp;&nbsp;

                    <Button className='generate_link_btn' variant="primary" onClick={handleGenerateLinkClick}>
                        Generate & Copy Link
                    </Button>

                </Form>

            </div>
            }


            {/* // Render ThankYou */}

            {!isLoading && isThankyou &&

                <div>
                    <div class=" d-flex justify-content-center align-items-center">
                        <div className="col-md-4">
                            <div className="border border-3 border-success"></div>
                            <div className="card  bg-white shadow p-5">
                                <div className="mb-4 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75"
                                        fill="currentColor" className="bi bi-check-circle text-success" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                </div>
                                <div className="text-center">
                                    <h1>Thank You !</h1>
                                    <p>Thanks for your donation. We have successfully received your donation Amount.
                                        Click on Below Button to download the PDF Invoice.  </p>
                                    <button onClick={() => downloadImage(base64_receipt_str)} class="btn btn-outline-success">Download PDF Invoice</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Donation_details